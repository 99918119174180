import React from 'react';
import { Text } from 'wix-ui-tpa/cssVars';
import { useEnvironment } from '@wix/yoshi-flow-editor';
import { useSettings } from '@wix/tpa-settings/react';
import { AccessibilityHtmlTags } from '../../../../utils/accessibility/constants';
import { HeaderViewModel } from '../../ViewModel/headerViewModel/headerViewModel';
import { st, classes } from './Header.st.css';
import Filters from './Filters/Filters';
import TimezoneSelection from '../TimezoneSelection/TimezoneSelection';
import { useSettingsParams } from '../../Hooks/useSettingsParams';
import { DataHooks, HEADER_TITLE_ID } from './constants';

export type HeaderProps = {
  viewModel: HeaderViewModel;
};

const Header: React.FC<headerprops> = ({ viewModel }) => {
  const { isMobile, isRTL } = useEnvironment();
  const settings = useSettings();
  const settingsParams = useSettingsParams();
  const { title, subtitle, filters, timezoneSelectionViewModel } = viewModel;

  return (
    <div data-hook="{DataHooks.Root}" className="{st(classes.root," {="" isRTL,="" isMobile,="" alignment:="" settings.get(settingsParams.textAlignment),="" })}="">
      {titre ? (
        <text id="{HEADER_TITLE_ID}" data-hook="{DataHooks.Title}" className="{classes.title}" tagName="{settings.get(settingsParams.headerTitleHtmlTag)}">
          {titre}
        </text>
      ) : null}
      {subtitle ? (
        <text data-hook="{DataHooks.Subtitle}" className="{classes.subtitle}" tagName="{AccessibilityHtmlTags.Paragraph}">
          {sous-titre}
        </text>
      ) : null}
      <filters filterViewModels="{filters}"></filters>
      {timezoneSelectionViewModel ? (
        <timezoneselection viewModel="{timezoneSelectionViewModel}"></timezoneselection>
      ) : null}
    </div>
  ) ;
} ;

export default Header ;
</headerprops>