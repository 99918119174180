import React from 'react';
import { classes } from './SrOnly.st.css';

export interface SrOnlyProps {
  'data-hook': string;
  id?: string;
  role?: string;
}

export const SrOnly: React.FC<any> = (props) => {
  retour <div {...props}="" className="{classes.srOnly}"></div>;
} ;
</any>